<template>
  <iframe
    :src="`https://www.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=${address}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
    :width="width"
    :height="height"
    frameborder="0"
    style="border: 0"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0"
  ></iframe>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    address: {
      type: String,
      default: '',
    },
  },
  beforeMount() {
    // console.log(this.address);
  },
};
</script>
